import React from 'react';
import NavBar from '../components/shared/NavBar';
import Footer from '../components/shared/Footer';
import Pastor from '../components/PastorPage/Pastor'
import PastorInfo from '../components/PastorPage/PastorInfo';
import StatementOfFaith from '../components/HomePage/StatementOfFaith';
const SOFPage = () => {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>   
            <StatementOfFaith />
            <br/>
        </>
    );
}

export default SOFPage;
