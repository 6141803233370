import React from 'react';

const Contact = () => {
    return (
        <section className='darkSection contact' id='contact'>
            Contact
            Phone
            (718) 934-2346
            
        </section>
    );
}

export default Contact;
